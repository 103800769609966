import React from 'react';

const UserProfile = ({ userName, userImage }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <img
                src={userImage}
                alt={userName}
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    marginRight: '10px' // Adds space between the image and text
                }}
            />
            <span style={{ fontWeight: 'bold' }}>{userName}</span>
        </div>
    );
};

export default UserProfile;