import React from 'react';
import { Routes, Route } from 'react-router-dom';
import VideoSharing from './VideoSharing';

function App({ initialData, platform }) {
  return (
    <div style={{ fontFamily: 'Roboto, sans-serif' }}>
      <Routes>
        <Route path="/:shareCode" element={<VideoSharing videoData={initialData} platform={platform} />} />
      </Routes>
    </div>
  );
}

export default App;
